import React from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import LoadingOverlay from 'react-loading-overlay';
import Indicator from 'react-spinners/FadeLoader'

const { useGlobalState } = createGlobalState({ networkings: 0 });

const MyLoadingOverlay = () => {
    const [networkings, setNetworkings] = useGlobalState('networkings');

    return <React.Fragment>
        <LoadingOverlay
            active={networkings > 0}
            spinner={<Indicator />}
            text={null}
        />
        <style jsx global>
            {`
                div._loading_overlay_overlay {
                    position: fixed !important;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            `}
        </style>
    </React.Fragment>
}

const promiseDecorator = async function (setNetworkings, promise) {
    try {
        setNetworkings(n => n + 1);
        const result = await promise;
        setNetworkings(n => n - 1);
        return result;
    } catch (e) {
        console.error(e);
        setNetworkings(n => n - 1);
        throw e;
    }
}

const asyncDecorator = function (setNetworkings, async) {
    return async function (...args) {
        try {
            setNetworkings(n => n + 1);
            const result = await async(...args);
            setNetworkings(n => n - 1);
            return result;
        } catch (e) {
            console.error(e);
            setNetworkings(n => n - 1);
            throw e;
        }
    }
}

export { useGlobalState as networkingsUseGlobalState, promiseDecorator, asyncDecorator, MyLoadingOverlay }

/**
import { networkingsUseGlobalState } from '../components/NetworkingIndicator';

//inside hook
const [_, setNetworkings] = networkingsUseGlobalState('networkings');


//try冒頭がおすすめ。catchとバランスしないといけない
setNetworkings(n => n + 1);

//catchの前後に(finallyがないので)
setNetworkings(n => n - 1);
 */